import React, { useContext, useEffect, useState } from "react";
import Spacing from "../../Components/Spacing/Spacing";
import Eye from "../../Components/PasswordEye/Eye";
import CloseEye from "../../Components/PasswordEye/CloseEye";
import { axiosApi } from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UseContext";

const PatientRegister = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [input, setInput] = useState({
    user_name: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setPageTitle, setUserDetails } = useContext(UserContext);

  useEffect(() => {
    setPageTitle("Patient Register");
  }, []);

  const validate = () => {
    const newErrors = {};
    if (input.password !== input.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if(input.password.length < 6) {
      newErrors.password = "Password should contain minimum 6 characters";
      toast.warning("Password should contain minimum 6 characters");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.user_name || !input.password) {
      return;
    }

    if (validate()) {
      setLoading(true);

      try {
        const data = { ...input };
        const response = await axiosApi.post(
          "/v1/patient/registerpatient",
          data
        );

        if (response.status === 201) {
          const { accessToken, refreshToken, user } = response.data;

          toast.success("Signup successful!");
          setInput({
            user_name: "",
            password: "",
            confirmPassword: "",
          });

          setTimeout(() => {
            navigate("/patient-login");
          }, 3000);
        } else {
          toast.error("Signup failed. Please try again.");
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.error || "User already exist");
        } else {
          toast.error("Signup error. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setInput({...input, password: value});
    if(value >= 6){
      setErrors((prevErrors) => ({...prevErrors, password: ""}));
    }
    if(value === input?.confirmPassword){
      setErrors((prevErrors) => ({...prevErrors, confirmPassword:""}));
    }
  }

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setInput({...input, confirmPassword: value});
    if(value === input?.password){
      setErrors((prevErrors) => ({...prevErrors, confirmPassword:""}));
    }
  }

  return (
    <>
      <Spacing lg={120} md={60} />
      <div className="container mt-5">
        <div className="booking_container patient_login_container">
          <div className="booking_form_card">
            <form onSubmit={handleSubmit}>
              <div className="patient_details_wrapper patient_details_form_wrapper">
                <div className="patient_login_card_header">
                  <p className="booking_confirmation_card_title">Signup</p>
                </div>
                <Spacing lg={35} md={20} />
                <label htmlFor="user_name" className="form-label mb-2 mt-2">
                  Email
                </label>
                <div className="mb-2">
                  <input
                    type="email"
                    className="form-control"
                    id="user_name"
                    placeholder="Email"
                    required
                    value={input.user_name}
                    onChange={(e) =>
                      setInput({ ...input, user_name: e.target.value })
                    }
                  />
                </div>
                <label htmlFor="password" className="form-label mb-2 mt-2">
                  Password
                </label>
                <div className="password-input-container mb-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    value={input.password}
                    placeholder="Password"
                    required
                    onChange={handlePasswordChange}
                  />
                  <div
                    className="icon-container"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Eye /> : <CloseEye />}
                  </div>
                </div>
                {errors.password && (
                  <p className="text-danger">
                    {errors.password}
                  </p>
                )}
                <label
                  htmlFor="confirm_password"
                  className="form-label mb-2 mt-2"
                >
                  Confirm Password
                </label>
                <div className="password-input-container">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    id="confirm_password"
                    value={input.confirmPassword}
                    placeholder="Confirm Password"
                    required
                    onChange={handleConfirmPasswordChange}
                  />
                  <div
                    className="icon-container"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Eye /> : <CloseEye />}
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="text-danger">{errors.confirmPassword}</p>
                )}
                <Spacing lg={40} md={30} />
                <div className="patient_login_btn_wrapper">
                  <button
                    className="booking_form_card_btn"
                    style={{ minWidth: "13rem", height: "2.75rem" }}
                    disabled={loading}
                  >
                    {loading ? <span className="loader"></span> : "Signup"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Spacing lg={120} md={80} />
      <ToastContainer position="top-center" autoClose={2000} />
    </>
  );
};

export default PatientRegister;
